@import '~pretty-checkbox/src/scss/variables';

$pretty--colors:(
  primary:#00A7FF,
  info:#00bcd4,
  success:#4caf50,
  warning:$pretty--color-warning, // default
  danger:$pretty--color-danger //default
);

@import '~pretty-checkbox/src/pretty-checkbox.scss';