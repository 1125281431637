body {
  background: #0D2027;
}

.App {
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  font-family: "Nanum Gothic";
  box-sizing: border-box;
  margin: 0;
  scroll-behavior: smooth;
  /* transition: 0.2s ease 0s; */
}
