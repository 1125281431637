:root {
  --colorOffWhite: none;
  --colorLightGray: #153845;
  --colorGray: #2A5362;
  --colorDarkGray: #545454;
  --colorBlack: #8FBDCE;
  --colorBlue: #71b0f1;
  --colorRed: #db6767;
  --baseBorderRadius: 2px;
  --gridGap: 2px;
  --keyCapHeight: 35px;
}

/* 60 column grid. */
/* 1u key (standard keysize) = 4 columns */
.keyboard {
  margin: auto;
  max-width: 500px;

  display: grid;
  grid-template-columns: repeat(52, 1fr);
  grid-gap: var(--gridGap);
  padding: 8px;
  background-color: var(--colorOffWhite);
  border-radius: var(--baseBorderRadius);
  /* box-shadow: 
    0 2px 2px 0 rgba(0,0,0,0.14),
    0 3px 1px -2px rgba(0,0,0,0.2), 
    0 1px 5px 0 rgba(0,0,0,0.12); */
}

/* See keycap sizing: https://deskthority.net/wiki/Keycap_size_by_keyboard */
/* Standard 1u key */
.keyboard__key {
  display: flex;
  grid-column: span 4;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: var(--keyCapHeight); */
  background-color: var(--colorLightGray);
  background-image: 
    linear-gradient(to bottom right, var(--colorLightGray), color(var(--colorLightGray) blackness(+10%)));
  border-radius: var(--baseBorderRadius);
  opacity: 0.5;
  
  /* Printed text on keycap */
}

.keyboard__key::before {
  content: "";
  z-index: 1;
}

/* Top of key */
.keyboard__key::after {
  content: attr(data-key-text);
  
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--colorBlack);
  opacity: 0.65;
  white-space: nowrap;

  position: absolute;
  /* top: 4px; */
  /* right: 5px; */
  /* bottom: 4px; */
  /* left: 5px; */
  /* display: block; */
  /* width: auto; */
  /* height: calc(100% - 8px); */
  /* background-color: inherit; */
  /* filter: contrast(105%); */
  /* border-radius: calc(var(--baseBorderRadius) + 2px); */
}

/* Keycap sizes */
/* 1.25u key */
.keyboard__key--1-25u {
  grid-column: span 5;
}

/* 1.5u key */
.keyboard__key--1-5u {
  grid-column: span 6;
}

/* 1.75u key */
.keyboard__key--1-75u {
  grid-column: span 7;
}

/* 2u key */
.keyboard__key--2u {
  grid-column: span 8;
}

/* 2.25u key */
.keyboard__key--2-25u {
  grid-column: span 9;
}

/* 2.5u key */
.keyboard__key--2-5u {
  grid-column: span 10;
}

/* 2.75u key */
.keyboard__key--2-75u {
  grid-column: span 11;
}

/* 6.25u space key */
.keyboard__key--6-25u {
  grid-column: span 25;
}

/* Keycap variations */
/* Modifier keys */
.keyboard__key--modifier {
  background-color: var(--colorGray);
  background-image: 
    linear-gradient(to bottom right, var(--colorGray), color(var(--colorGray) blackness(+10%)));
    
  &::before {
    font-size: 8px;
  }
}

/* Enter key */
.keyboard__key--enter {
  background-color: var(--colorBlue);
  background-image: 
    linear-gradient(to bottom right, var(--colorBlue), color(var(--colorBlue) blackness(+10%)));
  
}

/* Escape key */
.keyboard__key--escape {
  background-color: var(--colorRed);
  background-image: 
    linear-gradient(to bottom right, var(--colorRed), color(var(--colorRed) blackness(+10%)));
}

/* Body styling */
/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: 
    linear-gradient(to bottom right, var(--colorBlack), var(--colorDarkGray));
} */
