.c {
  position: absolute;
  will-change: transform, opacity;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  
}

.nothing {
  background: #182c35;
  border: 2px solid #182c35;
}

.front,
.back {
  background-size: cover;
}

.back {
  /* background-image: url(https://images.unsplash.com/photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i&auto=format&fit=crop); */
}

.front {
  /* background-image: url(https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80&auto=format&fit=crop); */
}

.container {
  /* display: flex;
  align-items: center;
  height: 100%;
  justify-content: center; */
  position: relative;
}
