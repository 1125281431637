:root {
  --duration: 1s;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.squiggle1 {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: var(--duration) draw 0s ease-in-out forwards;
}

.squiggle2 {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: calc(var(--duration)*1) draw calc(var(--duration)*1) linear forwards;
}

.squiggle3 {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: calc(var(--duration)*1) draw calc(var(--duration)*2) linear forwards;
}

.squiggle4 {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: calc(var(--duration)*1) draw calc(var(--duration)*3) linear forwards;
}

.App > svg {
  width: 200px;
  height: 200px;
}

@keyframes draw {
  to {
    stroke-dashoffset: 90%;
  }
}